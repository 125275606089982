import { makeStyles } from 'tss-react/mui';

const commonTextStyles = {
	textTransform: 'uppercase',
	fontSize: '12px',
	display: 'inline-block',
};

const useStyles = makeStyles()((theme) => ({
	announcementBar: {
		minHeight: '40px',
		backgroundColor: theme.palette.primary.main,
		padding: '10px 0',
		textAlign: 'center',
		color: theme.palette.common.white,

		'& > * + *::before': {
			content: '"|"',
			whiteSpace: 'pre',
			margin: '0 12px',
		},
	},
	item: {
		'& > * + *': {
			marginLeft: '8px',
		},
	},
	announcementText: {
		fontWeight: theme.typography.fontWeightSemiBold,
		letterSpacing: '3px',
		...commonTextStyles,
	},
	announcementLink: {
		fontWeight: theme.typography.fontWeightSemiBold,
		color: 'inherit',
		letterSpacing: '1px',
		textDecoration: 'underline',
		...commonTextStyles,
	},
}));

export default useStyles;
