import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// constants
import constants from '@/constants';

// components
import ImageComponent from '@/components/widgets/image';

//mui
import { AppBar, Box, Link as ButtonLink, Chip, Toolbar } from '@mui/material';

// utils
import { goToRoute } from '@/store/actions/navigation';
import { getPersistentData } from '@/utils/browserStorage/persistentData';

// styles
import useStyles from './style';

const MenuActions = React.memo(function MenuActions({ action }) {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	let handleActionClick = (action) => {
		let centre = getPersistentData(constants.cookie.kioskCentre);
		if (action.link === '/kiosk/') {
			dispatch(goToRoute(action.link + centre));
		} else {
			dispatch(goToRoute(action.link, {}, { centre: centre }));
		}
	};
	return (
		<Box>
			<Chip
				className={classes.chipButton}
				label={action.label}
				color="primary"
				onClick={() => handleActionClick(action)}
				clickable
			/>
		</Box>
	);
});

export default function KioskMenuBar({ secondaryBar, logo, actions }) {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	let contactClicked = () => {
		if (!secondaryBar) return;

		dispatch(goToRoute(secondaryBar.link));
	};

	return (
		<AppBar className={classes.appBar} position="fixed">
			<Toolbar className={classes.toolbar}>
				{secondaryBar && (
					<Box
						width="100%"
						height="100%"
						display="flex"
						justifyContent="flex-end"
					>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
						>
							<Box className={classes.contactContainer}>
								&nbsp;
								<ButtonLink
									onClick={contactClicked}
									color="primary"
									underline="always"
									className={classes.topButton}
								>
									{secondaryBar.label}
								</ButtonLink>
							</Box>
						</Box>
					</Box>
				)}
				<Box width="100%" height="100%" display="flex" alignItems="center">
					<Box className={classes.menuBarLeft}>
						<ImageComponent
							src={logo.url}
							title={logo.title}
							height={'40px'}
							priority={true}
							width={'150px'}
							loading="eager"
							alt={logo.alternativeText || ''}
						/>
					</Box>
					{actions && (
						<Box className={classes.menuBar}>
							{actions.map((action, index) => (
								<MenuActions action={action} key={index} />
							))}
						</Box>
					)}
				</Box>
			</Toolbar>
		</AppBar>
	);
}

KioskMenuBar.propTypes = {
	logo: PropTypes.object.isRequired,
	secondaryBar: PropTypes.object,
	actions: PropTypes.array,
};
