import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	appBar: {
		backgroundColor: theme.palette.common.white,
	},
	menuBarLeft: {
		cursor: 'pointer',
		[theme.breakpoints.down('md')]: {
			marginRight: theme.spacing(1.5),
		},
		flex: '1',
	},
	toolbar: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	topButton: {
		color: 'rgba(0,0,0,0.18)',
		fontSize: 'small',
		fontWeight: theme.typography.fontWeightBold,
	},
	contactContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: theme.spacing(1),
		cursor: 'pointer',
	},
	menuBar: {
		display: 'flex',
		justifyContent: 'end',
		alignItems: 'center',
		flexGrow: '1',
		spacing: '1',
	},
	chipButton: {
		backgroundColor: '#f26622',
		margin: '20px',
		color: theme.palette.common.white,
		fontSize: '0.9rem',
		width: '180px',
		height: '60px',
		lineHeight: '60px',
		fontWeight: theme.typography.fontWeightBold,
	},
}));

export default useStyles;
