import { isExternalUrl } from '@/store/actions';
import Link from 'next/link';
import PropTypes from 'prop-types';

// Material
import { Box } from '@mui/material';

// Styles
import useStyles from './style';

export default function AnnouncementBar({ data }) {
	const { classes } = useStyles();

	return (
		<Box className={classes.announcementBar}>
			{data.map(({ link, label, text }, index) => (
				<Box key={index} component="span" className={classes.item}>
					{text && (
						<span key={index} className={classes.announcementText}>
							{text}
						</span>
					)}
					{link &&
						label &&
						(isExternalUrl(link) ? (
							<a
								href={link}
								className={classes.announcementLink}
								target="_blank"
								rel="noreferrer"
							>
								{label}
							</a>
						) : (
							<Link href={link}>
								<a className={classes.announcementLink}>{label}</a>
							</Link>
						))}
				</Box>
			))}
		</Box>
	);
}

AnnouncementBar.propTypes = {
	data: PropTypes.array,
};

AnnouncementBar.defaultProps = {
	data: [],
};
