import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';
const BODY_CONTAINER_ID = 'BODY_CONTAINER';
const isPartnerPlatform = constants.platform === 'partner';

// components
import AnnouncementBar from '@/components/shared/announcementBar';
import LandingPageFooter from '@/components/shared/footer/landingPage';
import LandingPageMenuBar from '@/components/shared/menuBar/landingPage';
import Empty from '@/components/widgets/Empty';
import ScrollToId from '@/components/widgets/scrollToWidget/scrollToId';

// CSR Components
const ExitIntentModal = dynamic(
	() => import('@/components/widgets/exitIntentModal'),
	{
		ssr: false,
		loading: Empty,
	}
);
const ScrollToForm = dynamic(
	() => import('@/components/widgets/scrollToWidget/scrollToForm'),
	{
		ssr: false,
		loading: Empty,
	}
);

let WhatsAppChatBot = Empty,
	LoginSignupModal = Empty;

if (!isPartnerPlatform) {
	WhatsAppChatBot = dynamic(
		() => import('@/components/widgets/whatsAppChatBot'),
		{ ssr: false, loading: Empty }
	);
	LoginSignupModal = dynamic(
		() => import('@/components/widgets/loginSignupModal'),
		{ ssr: false, loading: Empty }
	);
}

// Material
import { Box } from '@mui/material';

// utils
import useAnnouncement from '@/utils/hooks/use-announcement';
import useFloatingButtons from '@/utils/hooks/use-floating-buttons';
import { LazyFooter } from '@/utils/withLazyLoad';

// Styles
import useStyles from './style';

const LandingPageLayout = (props) => {
	const isMobile = useSelector((state) => state.common.isMobile);
	const { classes } = useStyles({
		showBackgroundImage: props.showBackgroundImage && !isMobile,
	});
	const menuBarProps = useSelector((state) => state.menuBar.data);
	const footerProps = useSelector((state) => state.footer.data);
	const exitIntentModalProps = useSelector(
		(state) => state.exitIntentModal.data
	);
	const [showFloatingButtonConfig] = useFloatingButtons(props.resolvedUrl);
	const [announcementConfig] = useAnnouncement(props.resolvedUrl);

	const [widgetPositions, updateWidgetPositions] = React.useState(() => {
		const config = { whatsAppWidget: 1, scrollWidget: 10 };
		if (showFloatingButtonConfig.scroll && !showFloatingButtonConfig.chatbot) {
			config.scrollWidget = config.whatsAppWidget;
		}

		return config;
	});

	const setWidgetPositions = (config) => {
		if (showFloatingButtonConfig.scroll && !showFloatingButtonConfig.chatbot) {
			config.scrollWidget = config.whatsAppWidget;
		}
		updateWidgetPositions(config);
	};

	return (
		<>
			{menuBarProps && (
				<Box>
					<LandingPageMenuBar logo={menuBarProps.logo} />
				</Box>
			)}
			<Box className={classes.bodyContainer} id={BODY_CONTAINER_ID}>
				{announcementConfig.show && announcementConfig.data.length > 0 && (
					<AnnouncementBar data={announcementConfig.data} />
				)}
				{props.children}
			</Box>
			{footerProps && (
				<Box className={classes.footerContainer}>
					<LazyFooter
						Component={LandingPageFooter}
						logo={footerProps.logo}
						lazyLoad={props.hasDynamicZone}
					/>
				</Box>
			)}
			<LoginSignupModal />

			{isMobile && props.pageFormId && (
				<ScrollToForm
					scrollToId={props.pageFormId}
					buttonText={props.formButtonText}
					offset={-80}
					onHide={() => {
						setWidgetPositions({ whatsAppWidget: 1, scrollWidget: 10 });
					}}
					onShow={() => {
						setWidgetPositions({ whatsAppWidget: 10, scrollWidget: 19 });
					}}
					analyticsProps={{
						section: 'FLOATING_SECTION',
						eventName: 'CTA_BUTTON_CLICKED',
					}}
				/>
			)}
			{showFloatingButtonConfig.scroll && (
				<ScrollToId
					scrollToId={BODY_CONTAINER_ID}
					triggerThreshold={1000}
					widgetPosition={widgetPositions.scrollWidget}
				/>
			)}
			{showFloatingButtonConfig.chatbot && (
				<WhatsAppChatBot widgetPosition={widgetPositions.whatsAppWidget} />
			)}
			{exitIntentModalProps && (
				<ExitIntentModal
					title={exitIntentModalProps.title}
					description={exitIntentModalProps.description}
					genericButton={exitIntentModalProps.button}
					openFormModal={exitIntentModalProps.openFormModal}
					openLoginModal={exitIntentModalProps.openLoginModal}
					form={exitIntentModalProps.form}
					body={exitIntentModalProps.body}
					timeout={exitIntentModalProps.timeToShowInSeconds * 1000}
				/>
			)}
		</>
	);
};

export default LandingPageLayout;

LandingPageLayout.propTypes = {
	pageFormId: PropTypes.string,
	formButtonText: PropTypes.string,
	showBackgroundImage: PropTypes.bool,
	hasDynamicZone: PropTypes.bool,
};
LandingPageLayout.defaultProps = {
	showBackgroundImage: false,
	hasDynamicZone: false,
};
