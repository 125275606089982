import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	errorSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		margin: '40px 16px',
		'& > * + *': {
			marginTop: '16px',
		},
	},
	statusCode: {
		fontSize: '160px',
		fontWeight: theme.typography.fontWeightBold,
		[theme.breakpoints.down('sm')]: {
			fontSize: '90px',
		},
		[theme.breakpoints.down(281)]: {
			fontSize: '60px',
		},
	},
	title: {
		fontSize: '40px',
		fontWeight: theme.typography.fontWeightBold,
		lineHeight: 1.3,
		[theme.breakpoints.down(281)]: {
			fontSize: '24px',
		},
	},
	subTitle: {
		fontSize: '24px',
		maxWidth: '500px',
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down(281)]: {
			fontSize: '16px',
		},
	},
}));

export default useStyles;
