import { useDispatch, useSelector } from 'react-redux';

// components
import MenuBar from '@/components/shared/menuBar/kioskPage';
import LoginSignupModal from '@/components/widgets/loginSignupModal';

// Material
import { Box } from '@mui/material';

// action
import { logoutUser } from '@/store/actions';

// Styles
import useStyles from './style';

const KioskPageLayout = ({ children }) => {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const menuBarProps = useSelector((state) => state.menuBar.kiosk);

	React.useEffect(() => {
		dispatch(logoutUser());
	}, []);

	return (
		<>
			<Box>
				{menuBarProps && (
					<MenuBar
						logo={menuBarProps.logo}
						actions={menuBarProps.actions}
						secondaryBar={menuBarProps.secondaryBar}
					/>
				)}
				<Box className={classes.bodyContainer}>{children}</Box>
			</Box>
			<LoginSignupModal />
		</>
	);
};

export default KioskPageLayout;
