import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	footerContainer: {
		opacity: 0.98,
		maxWidth: theme.breakpoints.values.xl,
		margin: '0 auto',
		padding: theme.spacing(7.5, 0),
		alignItems: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			paddingTop: theme.spacing(4),
		},
	},
	footerLogoContainer: {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			paddingBottom: theme.spacing(2),
		},
	},
}));

export default useStyles;
