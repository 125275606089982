import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { showBackgroundImage }) => ({
	bodyContainer: {
		paddingTop: theme.spacing(10),
		background: showBackgroundImage
			? 'url(https://production-azent-website-cms-uploads.s3.ap-south-1.amazonaws.com/landing_page_bg_77ae7d6993.svg)'
			: 'unset',
		backgroundSize: '100% auto',
		backgroundPositionY: '-200px',
	},
	footerContainer: {
		backgroundColor: theme.palette.common.lightGrey,
	},
}));

export default useStyles;
