// Component
import ImageComponent from '@/components/widgets/image';
// Material
import { AppBar, Box, Toolbar } from '@mui/material';
// Default
import PropTypes from 'prop-types';
// Styles
import useStyles from './style';

export default function LandingPageMenuBar({ logo }) {
	const { classes } = useStyles();

	return (
		<Box>
			<AppBar className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Box>
						<ImageComponent
							src={logo.url}
							title={logo.title}
							height={'62px'}
							width={'200px'}
							priority={true}
							loading="eager"
							alt={logo.alternativeText}
						/>
					</Box>
				</Toolbar>
			</AppBar>
		</Box>
	);
}

LandingPageMenuBar.propTypes = {
	logo: PropTypes.object.isRequired,
};
