import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	appBar: {
		backgroundColor: theme.palette.common.white,
		position: 'fixed',
	},
	toolbar: {
		paddingTop: theme.spacing(1),
		height: theme.spacing(10),
	},
}));

export default useStyles;
