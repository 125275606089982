import Link from 'next/link';
import PropTypes from 'prop-types';

// constants
import { textValues } from './constants';

// material
import { Box, Button, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function ErrorSection(props) {
	const { classes } = useStyles();

	return (
		<Box className={classes.errorSection}>
			<Box>
				<Typography className={classes.statusCode}>
					{props.statusCode}
				</Typography>
				<Typography className={classes.title}>
					{props.statusCode === 404
						? textValues.notFoundTitle
						: textValues.errorTitle}
				</Typography>
				<Typography className={classes.subTitle}>
					{props.statusCode === 404
						? textValues.notFoundSubTitle
						: textValues.errorSubTitle}
				</Typography>
			</Box>
			<Link href="/">
				<a>
					<Button variant="contained" color="primary">
						{textValues.homePageButtonTitle}
					</Button>
				</a>
			</Link>
		</Box>
	);
}
ErrorSection.propTypes = {
	statusCode: PropTypes.number,
};
ErrorSection.defaultProps = {
	statusCode: 500,
};
