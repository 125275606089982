// Components
import ImageComponent from '@/components/widgets/image';
// Material
import { Grid } from '@mui/material';
// Default
import get from 'lodash/get';
import PropTypes from 'prop-types';
// Styles
import useStyles from './styles';

export default function LandingPageFooter({ logo }) {
	const { classes, theme } = useStyles();

	return (
		<Grid container spacing={0}>
			<Grid
				item
				container
				xs={12}
				className={classes.footerContainer}
				spacing={0}
			>
				<Grid item xs={12} md={3} className={classes.footerLogoContainer}>
					<ImageComponent
						src={get(logo, 'logo.data.attributes.url', '')}
						height={theme.spacing(9)}
						width={theme.spacing(32)}
						title={get(logo, 'title', '')}
						priority={true}
						alt={get(logo, 'logo.data.attributes.alternativeText', '')}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}

LandingPageFooter.prototype = {
	logo: PropTypes.object.isRequired,
};
